import {AJAX_WP_Query} from 'ajax-wp-query';
import Glide from '@glidejs/glide';

window.onload = function(){

    let ourTeam = document.querySelector('.glide');

	if (ourTeam) {
		let glideOurTeam = new Glide('.glide', {
			type: 'slider',
			perView:3,
            autoplay: 3000,
            animationDuration: 1000,
            animationTimingFunc: "cubic-bezier(0.165, 0.84, 0.44, 1)",
            peek: { before: 0, after: 0 },
            gap: 0,
            swipeThreshold: false,
            dragThreshold: false,
            bound: true,
            rewind: true,
            dragDistance: 1,
            swipeThreshold: 80,
            animationTimingFunc: "ease-out",
            animationCenter: true,
            breakpoints: {
				600: {
				  perView: 1,
                  autoplay: 3000,
				},
                1440: {
                    perView: 2,
                    autoplay: 3000,
                  },
            }
		});
		glideOurTeam.mount();
	}

}